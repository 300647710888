import React from "react";
import { Box } from "@mui/material";
import {PaymentsVerificationHistoryInterface} from "../../types";
import theme from "../../../../theme";
import {UtilComponent} from "./utils-component";

export interface IVerificationDetailHistoryPaymentsProps {
    verification: any;
}
export default function VerificationDetailHistoryPayments(props: IVerificationDetailHistoryPaymentsProps) {
    const {verification} = props;

    const getColor = (item: string) => {
        return !item ? "" : (theme.palette[item as keyof typeof theme.palette] as any).main;
    }

    return (
        <Box>
            <Box className="history-title bold">Payments Verification</Box>
            <Box className="history-table">
                <Box className="history-row history-table-header">
                    <Box className="history-date-column bold">Date</Box>
                    <Box className="history-action-column bold">Action</Box>
                    <Box className="history-payment-id-column bold">Payment ID</Box>
                    <Box className="history-transaction-id-column bold">Transaction ID</Box>
                    <Box className="history-score-column bold">Score(%)</Box>
                    <Box className="history-score-column bold">Omniscore(%)</Box>
                    <Box className="history-score-column bold">Details</Box>
                </Box>
                {verification?.history &&
                    verification.payments_verification?.map((el: PaymentsVerificationHistoryInterface, index: number) => {
                        return (
                            <Box className="history-row" key={index}>
                                <Box className="history-date-column">{el.created_at}</Box>
                                <Box className="history-action-column" style={{color: getColor(el.provider_result.type)}}>{el.provider_result.name}</Box>
                                <Box className="history-payment-id-column">{el.payment_id}</Box>
                                <Box className="history-transaction-id-column">
                                    <a href={UtilComponent.getKountLink(el.provider_transaction_id)} target="_blank" rel="noreferrer" style={{textDecoration: "none", color: "#337ab7"}}>
                                        {el.provider_transaction_id}
                                    </a>
                                </Box>
                                <Box className="history-score-column">{el.score}</Box>
                                <Box className="history-score-column">{el.omniscore}</Box>
                                <Box className="history-score-column">{el.details}</Box>
                            </Box>
                        );
                    })
                }
            </Box>
        </Box>
    );
}
